var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[(!_vm.Loading)?_c('div',{staticClass:"schedule-info",attrs:{"id":"scroll"}},[_c('div',{staticStyle:{"min-height":"95%"}},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"info-top"},[(_vm.scheduleDate.length > 0)?_c('div',{staticClass:"left-top"},[_c('span',[_vm._v(_vm._s(_vm.info.venue_time != 0 ? _vm.timestampToTime(_vm.info.venue_time) : _vm.timestampToTime(_vm.scheduleDate[0].start_time * 1000)))])]):_vm._e(),_c('div',{staticClass:"right-top",on:{"click":_vm.liveUrl}},[_c('img',{attrs:{"src":require("@/assets/schedule/video.svg"),"alt":""}}),_c('span',[_vm._v("进入直播")])])]),(_vm.scheduleDate.length > 0 || _vm.info.venue_position)?_c('div',{staticClass:"position"},[(_vm.scheduleDate.length > 0)?_c('p',{staticClass:"date"},[_c('img',{attrs:{"src":require("@/assets/RiCheng/white_time.svg"),"alt":""}}),_vm._v(_vm._s(_vm.scheduleDate[0].start_time_format)+"-"+_vm._s(_vm.scheduleDate[_vm.scheduleDate.length - 1].end_time_format)+" ")]):_vm._e(),(_vm.info.venue_position)?_c('p',[_c('img',{attrs:{"src":require("@/assets/RiCheng/site.svg"),"alt":""}}),_vm._v(_vm._s(_vm.info.venue_position)+" ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"assembly_name"},[_vm._v(" "+_vm._s(_vm.info.venue_title)+" ")]),_vm._l((_vm.scheduleDate),function(item,index){return _c('div',{key:index,staticClass:"bottom_box"},[_vm._m(0,true),_c('div',{staticClass:"bottom_right"},[_c('div',{staticClass:"agenda_title"},[_vm._v(_vm._s(item.agendum_title))]),_c('div',{staticClass:"bottom_time"},[_vm._v(" "+_vm._s(item.start_time_format)+"-"+_vm._s(item.end_time_format)+" ")]),_vm._l((item.people_library),function(j,k){return _c('div',{key:k,staticClass:"bottom_warp"},_vm._l((item.people_library[k]),function(i,kep){return _c('div',{key:i.id,on:{"click":function($event){return _vm.goAttendInfo(i.id, i)}}},[(
                    (kep != 0 &&
                      i.type_name !=
                        item.people_library[k][kep - 1].type_name) ||
                    kep == 0
                  )?_c('div',{staticClass:"bottom_library"},[_vm._v(" "+_vm._s(i.type_name)+" ")]):_vm._e(),_c('div',{staticClass:"bottom_info",class:{
                    magrintop:
                      kep != 0 &&
                      i.type_name ==
                        item.people_library[k][kep - 1].type_name,
                  }},[_c('div',{staticClass:"info_box"},[_c('div',{staticClass:"bottom_img",style:({
                        background:
                          i.avatar_url && i.avatar_id != 3 ? '' : _vm.themecolors,
                      })},[(i.avatar_url && i.avatar_id != 3)?_c('img',{attrs:{"src":i.avatar_url,"alt":""}}):_c('div',[_vm._v(" "+_vm._s(i.name.substr(0, 1))+" ")])]),_c('div',{staticClass:"bottom_name"},[_c('div',{staticClass:"name_size"},[_vm._v(_vm._s(i.name))]),_c('div',{staticClass:"bottom_name_hospital"},[_vm._v(_vm._s(i.hospital))])])]),_vm._m(1,true)])])}),0)}),(item.people_library.length == 0)?_c('div',{staticClass:"bottom_library",staticStyle:{"padding-left":"0.1rem"}},[_vm._v(" 暂无人员安排 ")]):_vm._e()],2)])})],2)]),_c('Support')],1):_c('van-loading',{attrs:{"color":_vm.themecolors,"text-color":_vm.themecolors}}),_c('sideBar')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"details_steps"},[_c('div',{staticClass:"details_steps_icon"}),_c('div',{staticClass:"details_steps_line"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item-right"},[_c('img',{attrs:{"src":require("@/assets/schedule/arrow-right.svg"),"alt":""}})])}]

export { render, staticRenderFns }